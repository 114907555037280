<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <!-- <v-icon left color="primary">mdi-account-multiple-check</v-icon> Voters
                 <v-spacer/> -->
                 <span style="width: 290px;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
                 <v-divider vertical inset class="ml-2 mr-2"/>
                        <v-btn fab dark small color="success"  @click="user={}, state='new', form=true"><v-icon small >mdi-plus</v-icon></v-btn>
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="voters"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                              <v-btn  text small left color="warning" @click="voter=item, state='update', form=true" >
                                <v-icon small>mdi-open-in-new</v-icon> 
                              </v-btn>
                            
                            <!-- <v-icon  x-small left color="info" @click="user=item, trans_dailog=true" >mdi-format-list-text</v-icon> -->
                            {{ item.prec }}</td>
                            <td>{{ item.fullname }}</td>
                            <td>{{ item.status }}</td>
                            <!-- <td>{{ item.role }}</td>
                             <td :class="STATUS_COLOR(item.status)">{{ item.status }}</td>
                            <td><v-icon left small :color="statusIndicator(item.last_login)">mdi-checkbox-blank-circle</v-icon> {{ $moment(item.last_login).fromNow()}}</td> -->
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    </v-container>
    <va-voter-form :show="form"  :selected="voter" :state="state" @DialogEvent="formEvent" />
   
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        user: {},
        search:'',
        areas:[],
        area:{},
        voter:{},
        voters:[],
        headers: [
          { text: 'Prec', value:"prec"},
            { text: 'Name', value:"fullname"},
             { text: 'Status', value:"status"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getUsers()
         
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      transEvent(){
        this.trans_dailog = false
      },
      statusIndicator(_time) {
          //var current = this.$moment(_time)
          var a = this.$moment()
            var b = this.$moment(_time)
           var diff = a.diff(b, 'hour')
           if(diff <=  3) {
              return 'success'
           } else if(diff > 3 && diff < 5) {
               return '#A5D6A7'
           } else {
             return '#989898'
           }
      },
      CountBarangay(area){
          var _count = area.reduce((res,item)=>{
                res += item.barangays.length
                return res
          },0) 
          return _count
      },
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
            console.log(data)
              this.voter = data.voter
              this.Save()
          }
      },

      getAreas(){
           this.$http.get("area/list",).then(response => {
            response.data.areas != null?this.areas = response.data.areas:this.areas =[]
           // console.log(this.areas)
          }).catch(e => {
            console.log(e.data)
          })
      },
      
      getUsers: function() { 
        this.$http.get("voter/list").then(response => {
          console.log(response.data)
            response.data.list != null?this.voters = response.data.list:this.voters =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    
      Save: function() {
        let method = 'voter/register'
        this.state != 'new'?method='voter/update':method = 'voter/register'
        console.log(method, this.voter)
        this.$http.post(method, this.voter).then(response => {
            response.data.status? this.setAlertColor('success'): this.setAlertColor('error')
            this.setAlertText(response.data.message)
            this.setAlert(true)
            this.getUsers()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>